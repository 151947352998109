import React, { useContext, useEffect } from "react"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import axios from "axios"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import jsonpAdapter from "axios-jsonp"
import SearchIcon from "@material-ui/icons/Search"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

export default function SearchInput(props) {
  const { t } = useTranslation()
  const { navigate } = useI18next()

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [inputValue, setInputValue] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (inputValue) fetch(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (state.searchedKeyword !== inputValue)
      setInputValue(state.searchedKeyword)
  }, [state.searchedKeyword])

  const fetch = () => {
    setLoading(true)
    axios({
      url:
        "https://suggestqueries.google.com/complete/search?q=" +
        escape(inputValue) +
        "&client=youtube&limit=5" +
        "&ds=yt",
      adapter: jsonpAdapter,
    }).then(res => {
      //console.log("res", res.data)
      setOptions(res.data[1].slice(0, 5))
      setLoading(false)
    })
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const handleResultSelection = (e, val) => {
    if (!val) return

    const query = Array.isArray(val) ? val[0] : val
    navigate("/")
    dispatch({ type: "SEARCH", payload: { keyword: query } })
    //props.onSearch(val[0])
  }

  return (
    <div>
      <Autocomplete
        inputValue={inputValue}
        freeSolo={true}
        className={`input-search-autocomplete ${
          props.navbar ? " input-search-autocomplete-navbar" : ""
        }`}
        open={open}
        onOpen={() => {
          inputValue.length && setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionSelected={option => option[0] === inputValue}
        getOptionLabel={option => option[0]}
        options={options}
        loading={loading}
        onChange={handleResultSelection}
        onInputChange={(e, val, reason) => {
          reason === "clear" && setInputValue("")
        }}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            className="input-search"
            placeholder={t("inputSearchPlaceholder")}
            variant="outlined"
            onChange={e => setInputValue(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="primary"
                      size={19}
                      style={{ marginRight: 15 }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: <SearchIcon className="input-search-icon" />,
            }}
          />
        )}
      />
    </div>
  )
}
