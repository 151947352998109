import React, { useEffect, useState, useContext } from "react"
import { Trans } from "gatsby-plugin-react-i18next"
import Cookies from "js-cookie"
import axios from "axios"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import CancelIcon from "@material-ui/icons/Cancel"
import Alert from "@material-ui/lab/Alert"

import { I18nextContext } from "gatsby-plugin-react-i18next"

const Footer = () => {
  const context = useContext(I18nextContext)
  const [showForm, setShowForm] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {
    const hasCookie = parseInt(Cookies.get("emailModal"))
    if (!hasCookie) {
      setShowForm(true)
    }
  }, [])

  const setCookie = () => {
    Cookies.set("emailModal", 1, { expires: 15 })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!email || !validateEmail(email)) {
      alert("Email not valid")
      return
    }

    axios
      .get("https://develobot.com/uramaki/save-email.php", {
        params: {
          email: email,
          language: context.language,
        },
      })
      .then(res => {
        setCookie()
        setSuccess(true)
      })
  }

  const closeDialog = () => {
    setCookie()
    setShowForm(false)
  }

  const validateEmail = email => {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
  }

  return (
    <>
      {showForm && (
        <div className="modal-email">
          <div className="modal-email-wrapper">
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <div className="modal-email-title">
                  <Trans>modal-email-title</Trans>
                </div>
              </Grid>
              <Grid item xs={2}>
                <IconButton className="btn-close" onClick={closeDialog}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>

            {!success && (
              <form onSubmit={handleSubmit} id="formUsersEmails">
                <p className="modal-email-content">
                  <Trans>modal-email-content</Trans>
                </p>

                <TextField
                  size="small"
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  onChange={e => setEmail(e.target.value)}
                  required
                  type="email"
                  placeholder="email@mail.com"
                />

                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 15 }}
                >
                  <Trans>modal-email-cta</Trans>
                </Button>
              </form>
            )}

            {success && (
              <Alert severity="success">
                <Trans>modal-email-success-text</Trans>
              </Alert>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
