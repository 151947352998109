import React from "react"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next"
import Container from "@material-ui/core/Container"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <Container>
        <p className="footer-text">
          <Trans>footerText</Trans>
        </p>
        <hr />
        <p>
          © {new Date().getFullYear()} Freedsound -{" "}
          <Link to="/contact">{t("contact")}</Link>
        </p>
      </Container>
    </footer>
  )
}

export default Footer
