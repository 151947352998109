import {
  useI18next,
  I18nextContext,
  useTranslation,
  Trans,
  Link,
} from "gatsby-plugin-react-i18next"
//import Link from "../components/Link/Link"
import React, { useContext, useState, useEffect } from "react"
import Logo from "../images/logo.svg"

import { LinkGatsby } from "gatsby"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

import SearchInput from "../components/SearchInput/SearchInput"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Hidden from "@material-ui/core/Hidden"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import MailIcon from "@material-ui/icons/Mail"
import HomeIcon from "@material-ui/icons/Home"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },

  item3: {
    order: 4,
    [theme.breakpoints.up("md")]: {
      order: 3,
    },
  },
  item4: {
    order: 3,
    [theme.breakpoints.up("md")]: {
      order: 4,
    },
  },

  logoWrapper: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      flexGrow: 0,
    },
  },
  logo: {
    width: 164,
    height: "auto",
    marginTop: 5,

    [theme.breakpoints.down("sm")]: {
      width: 120,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title_hide_sm: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  search: {
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  languageSelector: {
    color: "#fff",
    "&:before": {
      border: 0,
    },
    "&:hover:not(.Mui-disabled):before": {
      border: 0,
    },

    "& svg": {
      color: "rgb(255 255 255 / 54%)",
    },
  },

  navLink: {
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
  },
}))

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const { languages, navigate, changeLanguage } = useI18next()
  const context = useContext(I18nextContext)
  const classes = useStyles()
  const [pos, setPos] = useState("top")

  // drawer
  const [drawerState, setDrawerState] = React.useState({
    left: false,
  })

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState({ ...state, [side]: open })
  }

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button key="1" onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem button key="2" onClick={() => navigate("/charts")}>
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          <ListItemText primary={t("charts")} />
        </ListItem>

        <ListItem button key="3" onClick={() => navigate("/contact")}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={t("contact")} />
        </ListItem>
      </List>
    </div>
  )

  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 5) {
        setPos("scrolled")
      } else {
        setPos("top")
      }
    })
  }, [])

  return (
    <header>
      <div className={classes.grow}>
        <AppBar
          position="fixed"
          style={{
            boxShadow: pos === "scrolled" ? "" : "none",
          }}
        >
          <Toolbar style={{ minHeight: 65 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Hidden mdUp>
                <Grid item xs={3} style={{ display: "flex" }}>
                  <div className={classes.sectionMobile}>
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                      onClick={toggleDrawer("left", true)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Hidden>

              <Grid item xs={6} md={3} className={classes.logoWrapper}>
                <Link
                  to="/"
                  onClick={() => {
                    dispatch({ type: "SEARCH_CLEAR" })
                  }}
                >
                  <Logo className={classes.logo} />
                </Link>
              </Grid>

              <Grid item xs={12} md={6} className={classes.item3}>
                {state.searchedKeyword && (
                  <div className={classes.search}>
                    <SearchInput value={state.searchedKeyword} navbar />
                  </div>
                )}
              </Grid>

              <Grid item xs={3} md={3} className={classes.item4}>
                <div
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Hidden smDown>
                    <Link
                      to="/charts"
                      className={classes.navLink}
                      style={{ marginRight: 35 }}
                    >
                      <Trans>charts</Trans>
                    </Link>
                  </Hidden>

                  <FormControl>
                    <Select
                      className={classes.languageSelector}
                      value={context.language}
                      name="language"
                      labelId="lang-select-label"
                      id="lang-select"
                      renderValue={() => context.language}
                    >
                      {languages.map(lng => (
                        <MenuItem
                          key={t(lng)}
                          onClick={() => changeLanguage(lng)}
                          value={lng}
                        >
                          <Trans>{lng}</Trans>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={drawerState.left}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {sideList("left")}
        </SwipeableDrawer>
      </div>
    </header>
  )
}

export default Header
