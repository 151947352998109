/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./Footer/Footer"
import Player from "./Player/Player"
import MailSubscription from "./MailSubscription/MailSubscription"
import { isMobile } from "react-device-detect"
import "./layout.scss"

import AdFixedFooter from "./Ads/AdFixedFooter"
import AdPushNotificationGalaxion from "./Ads/AdPushNotificationGalaxion"
//import AdPop from "./Ads/AdPop"
//import AdPushNotification from "./Ads/AdPushNotification"
//import AdInPagePushNotification from "./Ads/AdInPagePushNotification"
//import AdInPagePushNotificationHilltop from "./Ads/AdInPagePushNotificationHilltop"
//import AdInPagePushNotification from './Ads/AdInPagePushNotification';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // // Declare a state variable to track the rendering status
  // const [isRendered, setIsRendered] = useState(false);

  // // Use the setTimeout function to delay the rendering
  // setTimeout(() => {
  //   setIsRendered(true);
  // }, 3000); // 10 seconds


  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Player />
      <MailSubscription />
      <Footer />

      <AdFixedFooter />
      <AdPushNotificationGalaxion />
      {/* <AdInPagePushNotificationHilltop /> */}
      {/* <AdInPagePushNotification />
      <AdPushNotification /> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
