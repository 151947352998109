import React, { useContext } from "react"
import YouTube from "react-youtube-embed"
import Fab from "@material-ui/core/Fab"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles({
  player: {
    bottom: 15,
    right: 15,
    position: "fixed",
    width: 320,
    height: 180,
  },

  iconButton: {
    position: "absolute",
    top: -13,
    right: -13,
    zIndex: 10000,
    width: 28,
    height: 28,
    minHeight: 28,
    overflow: "hidden",
  },
})

const Player = () => {
  const classes = useStyles()
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  return (
    <div>
      {state.playerVideoId && (
        <Box className={classes.player} boxShadow={5}>
          {/*<YouTube id={state.playerVideoId} appendSrc="?autoplay=true" />*/}

          <iframe
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
            frameBorder="0"
            src={`https://youtube-iframe-63c85.web.app/?id=${state.playerVideoId}`}
          ></iframe>

          <Fab
            size="small"
            className={classes.iconButton}
            color="primary"
            aria-label="close"
            onClick={() => {
              dispatch({ type: "PLAYER_CLOSE" })
            }}
          >
            <CloseIcon style={{ fontSize: 16 }} />
          </Fab>
        </Box>
      )}
    </div>
  )
}

export default Player
